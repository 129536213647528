<template>
  <div id="app" class="flex flex-col px-0 lg:px-6 max-w-md mx-auto">
    <img class="mx-auto h-16 my-3 w-auto" src="../assets/logo.png" alt="Sopecam"/>
    <p class="text-white w-full bg-neutral-500 text-sm text-center py-1">
      {{ $t("logo.description") }}
    </p>
    <div v-if="isNotVerified">
      <form class="bg-white shadow-md rounded" @submit.prevent="verifyOtp">
        <div class="py-8 px-6 mx-auto border-t border-gray-300 ">
          <label class="block text-gray-700 text-sm mb-2 float-left" for="phone">
            {{ $t("otp") }} : {{ this.form.phone}}
          </label>
          <input v-model="form.code"
                 class="appearance-none border rounded w-full py-1 px-2
             leading-tight focus:outline-none focus:shadow-outline" style="background-color: #e5e7eb"
                 id="code"
                 type="text"
          placeholder="">
          <span class="text-red-500 text-[0.775rem]" v-if="!formIsValid && form.code === ''">{{ $t("otpError") }}</span>

        </div>
        <div v-if="!isLoading" class="w-full mt-2 text-center">
          <button type="submit"
                  class="text-white bg-blue-600 focus:ring-0 form-medium rounded mb-4 text-sm px-5 py-2.5"
                  style="background-color: #10b981">
            {{ $t("submit") }}
          </button>
        </div>
        <LoaderComponent v-else/>
      </form>
    </div>
    <div v-else-if="isSuccess" class="flex flex-col justify-center items-center py-16 px-8">
      <svg xmlns="http://www.w3.org/2000/svg" style="color: #10b981" class="w-16 h-16" fill="none" viewBox="0 0 24 24"
           stroke="currentColor" stroke-width="1">
        <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"/>
      </svg>

      <p class="text-gray-500 text-md text-center mx-auto">
        {{ $t("message") }}
      </p>
    </div>
    <div v-else>
      <form class="bg-white shadow-md" @submit.prevent="submitForm">
        <SectionComponent>
          <label class="block text-gray-700 text-sm mb-2 float-left">
            1- {{ $t("title.lang.label") }}
          </label>

          <ul class="w-full grid grid-cols-3 gap-4 justify-items-start">
            <li class="relative">
              <input id="lang-fr" name="lang" type="radio" v-model="$i18n.locale" value="fr" class="form-radio"/>
              <label for="lang-fr" class="mx-2 mr-2 text-sm">{{ $t("title.lang.fr") }}</label>
            </li>

            <li class="relative">
              <input id="lang-en" name="lang" type="radio" v-model="$i18n.locale" value="en" class="form-radio"/>
              <label for="lang-en" class="mx-2 mr-2 text-sm">{{ $t("title.lang.en") }}</label>
            </li>
          </ul>
          <span class="text-red-500 text-[0.775rem]"
                v-if="!formIsValid && form.lang === ''">{{ $t("title.lang.error") }}</span>

        </SectionComponent>
        <SectionComponent>
          <label class="block text-gray-700 text-sm mb-2 float-left" for="phone">
            2- {{ $t("title.phone.label") }}
          </label>
          <input v-model="form.phone"
                 class="appearance-none border rounded w-full py-1 px-2
             leading-tight focus:outline-none focus:shadow-outline" style="background-color: #e5e7eb"
                 id="phone" minlength="8"
                 type="text">
          <span class="text-red-500 text-[0.775rem]" v-if="!formIsValid && form.phone === ''">{{
              $t("title.phone.error")
            }}</span>

        </SectionComponent>

        <SectionComponent>
          <label class="block text-gray-700 text-sm mb-2 float-left">
            3- {{ $t("title.gender.label") }}
          </label>

          <ul class="w-full grid grid-cols-3 gap-4 justify-items-start">
            <li class="relative">
              <input id="male" type="radio" v-model="form.gender" value="M" class="form-radio"/>
              <label for="male" class="mx-2 mr-2 text-sm">{{ $t("title.gender.male") }}</label>
            </li>

            <li class="relative">
              <input id="female" type="radio" v-model="form.gender" value="F" class="form-radio"/>
              <label for="female" class="mx-2 mr-2 text-sm">{{ $t("title.gender.female") }}</label>
            </li>
          </ul>
          <span class="text-red-500 text-[0.775rem]"
                v-if="!formIsValid && form.gender === ''">{{ $t("title.gender.error") }}</span>

        </SectionComponent>

        <SectionComponent>
          <label class="block text-gray-700 text-sm mb-2" for="age">
            4- {{ $t("title.age.label") }}
          </label>
          <InputDateComponent v-model:value="form.age" :value="form.age" />
          
          <span class="text-red-500 text-[0.775rem]" v-if="!formIsValid && form.age === ''">{{
              $t("title.age.error")
            }}</span>
        </SectionComponent>

        <SectionComponent>
          <label class="text-gray-700 text-sm mb-2" for="region">
            5- {{ $t("title.region.label") }}
          </label>

          <select v-model="form.region" id="region" @change="handleChangeRegion"
                  class="border-2 border-gray-200 rounded w-full py-1 px-2 text-gray-700 mt-2"
                  style="background-color: #e5e7eb">
            <option disabled selected value="">{{ $t("title.region.select") }}</option>
            <option value="Centre">{{ $t("title.region.first") }}</option>
            <option value="Littoral">{{ $t("title.region.second") }}</option>
            <option value="Ouest">{{ $t("title.region.third") }}</option>
            <option value="Nord-Ouest">{{ $t("title.region.fourth") }}</option>
            <option value="Sud-Ouest">{{ $t("title.region.five") }}</option>
            <option value="Sud">{{ $t("title.region.six") }}</option>
            <option value="Est">{{ $t("title.region.seven") }}</option>
            <option value="Adamaoua">{{ $t("title.region.eight") }}</option>
            <option value="Nord">{{ $t("title.region.nine") }}</option>
            <option value="Extreme-Nord">{{ $t("title.region.ten") }}</option>
          </select>
          <span class="text-red-500 text-[0.775rem]"
                v-if="!formIsValid && form.region === ''">{{ $t("title.region.error") }}</span>
        </SectionComponent>
        <SectionComponent>
          <label class="text-gray-700 text-sm" for="town">
            6- {{ $t("title.town.label") }}
          </label>

          <select v-model="form.town" id="town" @change="handleChangeTown"
                  class=" border-2 border-gray-200 rounded bg-white
                   w-full py-1 px-2 text-gray-700 mt-2" style="background-color: #e5e7eb">
            <option disabled selected value="">{{ $t("title.town.select") }}</option>
            <option v-for="(option, index) in filteredOptions" :key="index" :value="option.value">
              {{ option.label }}
            </option>
          </select>

          <input v-if="showInput" v-model="form.townVal"
                 class="appearance-none border rounded w-full py-2 px-3 mt-4
             leading-tight focus:outline-none focus:shadow-outline" style="background-color: #e5e7eb"
                 id="phone"
                 type="text">
          <span class="text-red-500 text-[0.775rem]"
                v-if="!formIsValid && (form.town === '' || (form.town ==='Autre' && form.townVal === ''))">
            {{ $t("title.town.error1") }} {{
              form.town ? $t("title.town.error2") : $t("title.town.error3")
            }} {{ $t("title.town.error4") }}
          </span>
        </SectionComponent>

        <div v-if="!isLoading" class="w-full mt-2 text-center">
          <button type="submit"
                  class="text-white bg-blue-600 focus:ring-0 form-medium rounded mb-4 text-sm px-5 py-2.5"
                  style="background-color: #10b981">
            {{ $t("submit") }}
          </button>
        </div>
        <LoaderComponent v-else/>
      </form>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import '../assets/radio.css'
import SectionComponent from "@/components/SectionComponent.vue";
import LoaderComponent from "@/components/LoaderComponent.vue";
import InputDateComponent from "@/components/InputDateComponent.vue";

export default {
  components: {LoaderComponent, SectionComponent, InputDateComponent},
  data() {
    return {
      options: [
        {value: 'Yaoundé', label: 'Yaoundé', parent: 'Centre'},
        {value: 'mbalmayo', label: 'Mbalmayo', parent: 'Centre'},
        {value: 'Bafia', label: 'Bafia', parent: 'Centre'},
        {value: 'Autre', label: this.$i18n.t("title.town.other"), parent: 'Centre'},
        {value: 'Douala', label: 'Douala', parent: 'Littoral'},
        {value: 'Edea', label: 'Edea', parent: 'Littoral'},
        {value: 'Nkongsamba', label: 'Nkongsamba', parent: 'Littoral'},
        {value: 'Autre', label: this.$i18n.t("title.town.other"), parent: 'Littoral'},
        {value: 'Bafoussam', label: 'Bafoussam', parent: 'Ouest'},
        {value: 'Dschang', label: 'Dschang', parent: 'Ouest'},
        {value: 'Foumban', label: 'Foumban', parent: 'Ouest'},
        {value: 'Autre', label: this.$i18n.t("title.town.other"), parent: 'Ouest'},
        {value: 'Bamenda', label: 'Bamenda', parent: 'Nord-Ouest'},
        {value: 'Bafut', label: 'Bafut', parent: 'Nord-Ouest'},
        {value: 'Ndop', label: 'Ndop', parent: 'Nord-Ouest'},
        {value: 'Autre', label: this.$i18n.t("title.town.other"), parent: 'Nord-Ouest'},
        {value: 'Buea', label: 'Buea', parent: 'Sud-Ouest'},
        {value: 'Limbe', label: 'Limbe', parent: 'Sud-Ouest'},
        {value: 'Kumba', label: 'Kumba', parent: 'Sud-Ouest'},
        {value: 'Autre', label: this.$i18n.t("title.town.other"), parent: 'Sud-Ouest'},
        {value: 'Ebolowa', label: 'Ebolowa', parent: 'Sud'},
        {value: 'Sangmelima', label: 'Sangmelima', parent: 'Sud'},
        {value: 'Kribi', label: 'Kribi', parent: 'Sud'},
        {value: 'Autre', label: this.$i18n.t("title.town.other"), parent: 'Sud'},
        {value: 'Bertoua', label: 'Bertoua', parent: 'Est'},
        {value: 'Garoua Boulai', label: 'Garoua Boulai', parent: 'Est'},
        {value: 'Abong Mbang', label: 'Abong Mbang', parent: 'Est'},
        {value: 'Autre', label: this.$i18n.t("title.town.other"), parent: 'Est'},
        {value: 'Ngaoundere', label: 'Ngaoundere', parent: 'Adamaoua'},
        {value: 'Mienganga', label: 'Mienganga', parent: 'Adamaoua'},
        {value: 'Ngoundal', label: 'Ngoundal', parent: 'Adamaoua'},
        {value: 'Autre', label: this.$i18n.t("title.town.other"), parent: 'Adamaoua'},
        {value: 'Garoua', label: 'Garoua', parent: 'Nord'},
        {value: 'Figuil', label: 'Figuil', parent: 'Nord'},
        {value: 'Tcholliré', label: 'Tcholliré', parent: 'Nord'},
        {value: 'Autre', label: this.$i18n.t("title.town.other"), parent: 'Nord'},
        {value: 'Maroua', label: 'Maroua', parent: 'Extreme-Nord'},
        {value: 'Kousseri', label: 'Kousseri', parent: 'Extreme-Nord'},
        {value: 'Yagoua', label: 'Yagoua', parent: 'Extreme-Nord'},
        {value: 'Autre', label: this.$i18n.t("title.town.other"), parent: 'Extreme-Nord'},
      ],
      filteredOptions: [],
      isSuccess: false,
      isNotVerified: false,
      isLoading: false,
      showInput: false,
      formIsValid: true,
      form: {
        phone: '',
        lang: this.$i18n.locale,
        town: '',
        townVal: '',
        gender: '',
        region: '',
        code: '',
        age: '',
        editionId: 1,
      }
    }
  },

  mounted() {
    console.log(this.form.editionId);
  },
  methods: {
    submitForm(event) {
      // prevent default form submission behavior
      event.preventDefault();
      const url = new URL(window.location.href.replace("#/", ""))
      const mode = new URLSearchParams(url.search).get("mode")
      console.log(url);

      if (this.form.phone !== '' &&
          this.form.age !== '' &&
          this.form.lang !== '' &&
          (this.form.town !== '' || (this.form.town === 'Autre' && this.form.townVal !== '')) &&
          this.form.region !== '' &&
          this.form.gender !== '') {
        this.form.town = this.form.town !== 'Autre' ? this.form.town : this.form.townVal;
        this.isLoading = true;
        this.form.lang = this.$i18n.locale;
        if(mode){
          this.form.mode = mode;
        }

        axios.post(`https://ms-optin.nexah.net/api/v1/client-create/`+this.form.editionId, this.form).then((response) => {
          this.isSuccess = response.data.data.is_verified??true
          this.isNotVerified = !response.data.data.is_verified??true
          this.isLoading = false
        }).catch(()=>{
          this.isLoading=false
        })
      } else {
        this.formIsValid = false;
      }
    },

    verifyOtp(event) {
      // prevent default form submission behavior
      event.preventDefault();
      this.isLoading = true
      if (this.form.phone !== '' && this.form.code !== '') {
        axios.post(`https://ms-optin.nexah.net/api/v1/verify-code/`+this.form.editionId, this.form).then(() => {
          this.isSuccess=true
          this.isNotVerified=false
          this.isLoading = false
        })
      } else {
        this.formIsValid = false;
      }
    },

    handleChangeRegion() {
      this.form.town = "";
      this.filteredOptions = this.options.slice().filter(option => option.parent === this.form.region)
    },

    handleChangeTown(e) {
      this.showInput = e.target.value === 'Autre';
    }
  }
}

</script>