<template>
  <div class="py-3 px-6 border-t border-gray-300">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SectionComponent",
}
</script>
<script setup>
</script>