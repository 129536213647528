<template>
  <OptinForm msg="Welcome to Your Vue.js App"/>
</template>

<script>
import OptinForm from "@/view/OptinForm.vue";
import "@fontsource/poppins";

export default {
  name: 'App',
  components: {
    OptinForm
}
}
</script>

<style>
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
