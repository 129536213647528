import {createApp} from 'vue'
import App from './App.vue'
import './assets/tailwind.css'
import {createI18n} from "vue-i18n";

const messages = {
    en: {
        submit: 'Send',
        otp:'Enter the code sent to your phone number',
        otpError:'Please enter your verification code received by sms',
        message: 'Congratulations! Successful subscription to Nexah Mobile News. You will now receive your Cameroon tribune payment link every day.',
        logo: {
            description: 'Your Cameroon tribune at your fingertips'
        },
        title: {
            lang: {
                label: 'Your language',
                en: 'English',
                fr: 'French',
                error: 'You have to choose a language',
            },
            phone: {
                label: 'Your phone number',
                error: 'You have to enter your phone number',
            },
            gender: {
                label: 'Your gender',
                male: 'Male',
                female: 'Female',
                error: 'You have to select a gender ',
            },
            age: {
                label: 'Birth date',
                error: 'You have to enter your age',
            },
            region: {
                label: 'Your region',
                first: 'Central region',
                second: 'Littoral region',
                third: 'West region',
                fourth: 'Northwest region',
                five: 'Southwest region',
                six: 'South region',
                seven: 'Eastern region',
                eight: 'Adamaoua region',
                nine: 'North region',
                ten: 'Far north region',
                select: 'Select a region',
                error: 'You have to select a region',
            },
            town: {
                label: 'Your Town',
                select: 'Select a town',
                other: 'Other',
                error1: 'You have to',
                error2: 'enter',
                error3: 'select',
                error4: 'your town',
            },
        },
        input_date: {
          day: "Day",
          month: "Month",
          year: "Year"
        },
        month_list: {
          january: "January",
          february: "February",
          march: "March",
          april: "April",
          may: "May",
          june: "June",
          july: "July",
          august: "August",
          september: "September",
          october: "October",
          november: "November",
          december: "December"
        },
    },
    fr: {
        submit: 'Envoyer',
        otp:'Entrer le code envoyé sur votre numéro de téléphone',
        otpError:'Veuillez saisir votre code de vérification reçu par sms',
        message: 'Félicitations ! Souscription au service Nexah Mobile News réussie. Vous recevrez désormais le lien de paiement de votre Cameroon tribune tous les jours.',
        logo: {
            description: 'Votre Cameroon tribune à portée de main'
        },
        title: {
            lang: {
                label: 'Votre langue',
                en: 'English',
                fr: 'Français',
                error: 'Vous devez choisir votre langue',
            },
            phone: {
                label: 'Votre numéro de téléphone',
                error: 'Vous devez saisir votre numéro de téléphone',
            },
            gender: {
                label: 'Votre genre',
                male: 'Masculin',
                female: 'Feminin',
                error: 'Vous devez selectionner un genre',
            },
            age: {
                label: 'Date de naissance',
                error: 'Vous devez renseigner votre date de naissance',
            },
            region: {
                label: 'Votre région',
                select: 'Selectionner une région',
                first: 'Région du Centre',
                second: 'Région du Littoral',
                third: 'Région de l\'Ouest',
                fourth: 'Région du Nord-Ouest',
                five: 'Région du Sud-Ouest',
                six: 'Région du Sud',
                seven: 'Région d l\'Est',
                eight: 'Région de l\'Adamaoua',
                nine: 'Région du Nord',
                ten: 'Région de l\'Extreme-nord',
                error: 'Vous devez selectionner une région',
            },
            town: {
                label: 'Votre ville',
                select: 'Selectionner une ville',
                other: 'Autre',
                error1: 'Vous devez',
                error2: 'entrer',
                error3: 'selectionner',
                error4: 'votre ville',
            },
        },
        input_date: {
            day: "Jour",
            month: "Mois",
            year: "Année"
        },
        month_list: {
            january: "Janvier",
            february: "Fevrier",
            march: "Mars",
            april: "Avril",
            may: "Mai",
            june: "Juin",
            july: "Juillet",
            august: "Août",
            september: "Septembre",
            october: "Octobre",
            november: "Novembre",
            december: "Décembre"
        },
    }
}

const i18n = createI18n({
    locale: 'fr',
    fallbackLocale: 'en',
    messages,
})

const app = createApp(App)

app.use(i18n)
app.mount('#app')
